import AxiosApi from '@/services/AxiosApi';
import store from "../store";

const TrainingContext = {
    async load() {
        const context = await AxiosApi.get('/context', {
            params: {
                domain: window.location.hostname,
                // domain: 'https://schade-voorkomen.astrazeneca.vvcr-prodrive.nl',
            }
        });

        await store.dispatch('setContext', context.data);
    },

    getTrainingLabel() {
        return 'verkeersregels-nl';
    },

    getOrganisation() {
        return store.getters.organisation.toLowerCase().replace(' ', '-');
    },

    getHelprTrainingLabel() {
        return store.getters.helperLabel;
    },

    getHelprTrainingBot() {
        return store.getters.helperUrl;
    },

    getLanguage() {
        return store.getters.language;
    },

    getBrowserTitle() {
        return store.getters.content('pageTitle') + ' | VVCR Prodrive';
    },

    getClientId() {
        return store.getters.clientId;
    },

    getClientSecret () {
        return store.getters.clientSecret;
    }
};

export default TrainingContext
