<template>
    <v-main>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="11" md="10" lg="8" xl="6">
                    <v-card class="elevation-12">
                        <HeaderImage/>
                        <div class="video-container">
                            <vimeo-player ref="player" :video-id="videoId" :options="{'responsive':true}" @ended="onVideoEnd"></vimeo-player>
                        </div>
                        <v-card-actions class="justify-center text--disabled" align="center" justify="center">
                            <v-icon>mdi-information-variant</v-icon> {{ $t('slide.message-below-video') }}
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import HeaderImage from './components/HeaderImage';
import TestService from './services/TestService';
import CanvasConfetti from 'canvas-confetti';
import IssueService from "@/services/IssueService";

export default {
    components: {
        HeaderImage,
    },

    computed: {
        slide () {
            return this.$store.getters.getSlideById(parseInt(this.$route.params.slideId));
        },

        videoId () {
            return String(this.slide.videoId);
        },

        player () {
            return this.$refs.player;
        },

        isFinalSlide() {
            return !this.slide.next && !this.slide.chapter.next && 0 === this.slide.questions.length;
        },
    },

    watch: {
        slide() {
            this.initializePlyr();
        }
    },

    data: () => ({
        chapterWasFinishedBeforeHand: false,
    }),

    mounted() {
        this.$root.$on('playVideo', this.play);

        if (this.slide.chapter.isFinished) {
            this.chapterWasFinishedBeforeHand = true;
        }

        this.initializePlyr();

        if (this.isFinalSlide) {
            setTimeout(() => {
                CanvasConfetti({
                    angle: 45,
                    particleCount: 90,
                    spread: 70,
                    origin: { y: 0.6, x: 0 }
                });
                CanvasConfetti({
                    angle: 135,
                    particleCount: 90,
                    spread: 70,
                    origin: { y: 0.6, x: 1 }
                });
            }, 1000);
        }
    },

    beforeDestroy() {
        this.$root.$off('playVideo', this.play);
    },

    methods: {
        initializePlyr () {
            if (this.player.videoId !== this.videoId) {
                this.player.update(this.videoId);
            }
        },

        play() {
            this.player.play();
        },

        onVideoEnd() {
            if (0 === this.slide.questions.length) {
                TestService.finishSlide(this.slide);
            }

            try {
                if ('helprAPI' in window) {
                    window.helprAPI.sendMessage("/end_video");
                }
            } catch (e) {
                IssueService.log(e);
            }
        },

        navigateToChapterOverview (firstTime = false) {
            let slide = this.slide;

            if (firstTime) {
                // Redundancy
                TestService.finishSlide(slide);
            }

            this.$router.push({
                name: 'chapter',
                params: {
                    chapterId: slide.chapter.id,
                }
            });
        },

        navigateToQuestion () {
            let slide = this.slide,
                question;

            if (slide.nextQuestion) {
                question = slide.nextQuestion;
            } else {
                question = slide.questions[0];
            }

            // Go to first question
            this.$router.push({
                name: 'question',
                params: {
                    chapterId: slide.chapter.id,
                    slideId: slide.id,
                    questionId: question.id,
                }
            });
        },

        navigateToNextSlide () {
            let slide = this.slide;

            TestService.finishSlide(slide);

            if (!slide.next) {
                return;
            }

            this.$router.push({
                name: 'slide',
                params: {
                    chapterId: slide.chapter.id,
                    slideId: slide.next.id,
                }
            });
        },
    }
};
</script>

<style scoped>
img.video-placeholder {
    width: 100%;
}
.video-container {
    margin-top: 10px;
}
</style>
