<template>
    <v-app v-bind:class="organisation">
        <AdminNavigation v-if="loggedInUser && loggedInUser.isAdmin" v-bind:preventRedirect="preventRedirect" @setPreventRedirect="setPreventRedirect"/>

        <v-app-bar app color="transparent" flat>
            <v-spacer></v-spacer>

            <v-menu v-if="loggedInUser" bottom left offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn text depressed v-on="on">
                        <v-icon left>mdi-account-circle</v-icon>
                        {{loggedInUser.name}}
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item :to="{ name: 'privacyPolicy' }">
                        <v-list-item-title>{{ $t('global.privacy-policy-link-label') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-on:click="isShowingMyAccountModal = true">
                        <v-list-item-title>{{ $t('global.my-account-section-title') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout">
                        <v-list-item-title>{{ $t('global.logout-action-label') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <router-view></router-view>

        <v-footer color="transparent" class="mb-4 mr-4">
            <v-spacer></v-spacer>
            <div class="powered-by">
                <span class="white--text">Powered by</span>
                <a href="https://vvcr-prodrive.nl/" target="_blank">
                    <v-img width="160" src="/images/prodriveacademy-logo-white.png" contain />
                </a>
            </div>
        </v-footer>

        <v-dialog v-model="isShowingMyAccountModal" max-width="400">
            <v-card>
                <v-card-title class="headline">{{ $t('global.my-account-section-title') }}</v-card-title>
                <v-card-text>
                    <template v-if="!isChangingName && !isChangingPassword">
                        <h3>{{ $t('global.account-name-label') }}</h3>
                        <p class="fs-18" v-if="loggedInUser">
                            {{loggedInUser.name}}
                            <v-btn color="primary" text @click="onChangeNameClick">{{ $t('global.account-name-change-action-label') }}</v-btn>
                        </p>

                        <template v-if="canChangePassword">
                            <h3>{{ $t('global.account-password-label') }}</h3>
                            <p class="fs-18">
                                &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
                                <v-btn color="primary" text @click="onChangePasswordClick">{{ $t('global.account-password-change-action-label') }}</v-btn>
                            </p>
                        </template>

                        <h3>{{ $t('global.reset-training-section-title') }}</h3>
                        <p class="fs-18">
                            <span v-if="test.finishedAt">{{ $t('global.reset-training-for-finished-training-message') }}</span>
                            <span v-else>{{ $t('global.reset-training-for-unfinished-training-message') }}</span>
                            <br />
                            <v-btn color="primary" text @click="confirmResetTestDialog = true">{{ $t('global.reset-training-action-label') }}</v-btn>
                        </p>

                        <h3>{{ $t('global.debug-info-helpr-id-label') }}</h3>
                        <p class="fs-18 caption">
                            <template v-if="loggedInUser">
                                {{loggedInUser['helpr-id']}}
                            </template>
                            <template v-else>
                              {{ $t('global.debug-info-id-not-available-message') }}
                            </template>
                        </p>
                    </template>
                    <template v-else>
                        <template v-if="isChangingName">
                            <v-text-field v-model="newName" ref="formName" type="text" prepend-icon="mdi-account-outline" :label="$t('registration.input-field-name')" :disabled="isSubmitting" :rules="nameRules" :validate-on-blur="!isFormNameValid" required></v-text-field>
                        </template>
                        <template v-if="isChangingPassword">
                            <v-text-field v-model="newPassword" ref="formPassword" type="password" prepend-icon="mdi-fingerprint" :label="$t('registration.input-field-password')" :disabled="isSubmitting" :rules="passwordRules" :validate-on-blur="!isFormPasswordValid" required></v-text-field>
                            <label>{{ $t('global.password-requirements') }}</label>
                        </template>
                    </template>
                </v-card-text>

                <v-card-actions v-if="!isChangingName && !isChangingPassword">
                    <v-btn color="primary" block text @click="isShowingMyAccountModal = false">{{ $t('global.close-menu') }}</v-btn>
                </v-card-actions>
                <v-card-actions v-else>
                    <v-btn color="primary" text @click="onCancelChangeAccount">{{ $t('global.change-account-name-cancel-action-label') }}</v-btn>
                    <v-spacer/>
                    <v-btn color="primary" @click="onChangeClick" :disabled="!(isFormNameValid || isFormPasswordValid) || isSubmitting">{{ $t('global.change-account-name-confirm-action-label') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirmResetTestDialog" max-width="340">
            <v-card>
                <v-card-title class="headline">{{ $t('global.reset-training-confirm-dialog-title') }}</v-card-title>
                <v-card-text>{{ $t('global.reset-training-confirm-dialog-message') }}</v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="confirmResetTestDialog = false">{{ $t('global.reset-training-cancel-action-label') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="onSessionResetConfirm">{{ $t('global.reset-training-confirm-action-label') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import AuthenticationManager from './services/AuthenticationManager';
import AxiosApi from './services/AxiosApi';
import AdminNavigation from './components/AdminNavigation';
import TrainingContext from './services/TrainingContext';
import TestService from './services/TestService';
import IssueService from './services/IssueService';

export default {
    name: 'App',

    components: {
        AdminNavigation,
    },

    data: () => ({
        isShowingMyAccountModal: false,
        confirmResetTestDialog: false,
        isChangingName: false,
        isChangingPassword: false,
        isSubmitting: false,
        newName: '',
        newPassword: '',
        isFormNameValid: false,
        isFormPasswordValid: false,
        preventRedirect: false,
        didInitHelperCalls: false,
        didInitialiseRoute: false,
        didLoadUser: false,
        nameRules: [
            (value) => {
                return !!(value && 5 < value.length && value.indexOf(' ')) || window.translate('registration.input-field-name-validation-message');
            },
        ],
        passwordRules: [
            (value) => {
                return /^(?=.*[a-z])(?=.*)(?=.*\d).{8,100}$/.test(value) || window.translate('registration.input-field-password-validation-message');
            },
        ],
    }),

    async mounted() {
        window.addEventListener("error", this.onError);

        document.title = TrainingContext.getBrowserTitle();

        window.translate = this.$t.bind(this);

        // Show organisation related content
        let style = document.createElement('style');
        document.head.appendChild(style);
        style.sheet.addRule(`.hidden.${this.organisation}`, 'display: initial', 0);
        style.sheet.insertRule(`.hidden.${this.organisation} { display: initial; }`, 0);

        if ('complete' === window.document.readyState) {
            await this.initHelprMethods();
        } else {
            window.addEventListener("load", this.initHelprMethods);
        }
    },

    beforeDestroy() {
        window.removeEventListener("load", this.initHelprMethods);
        window.removeEventListener("error", this.onError);
    },

    watch: {
        newName () {
            this.$nextTick(() => {
                if (!this.$refs.hasOwnProperty('formName') || !this.isChangingName) {
                    this.isFormNameValid = false;
                } else {
                    this.isFormNameValid = this.$refs.formName.validate();
                }
            });
        },
        newPassword () {
            this.$nextTick(() => {
                if (!this.$refs.hasOwnProperty('formPassword') || !this.isChangingPassword) {
                    this.isFormPasswordValid = false;
                } else {
                    this.isFormPasswordValid = this.$refs.formPassword.validate();
                }
            });
        },
        loggedInUser () {
            if (!this.loggedInUser) {
                return;
            }

            this.didLoadUser = true;

            if (!this.didInitHelperCalls && this.didInitialiseRoute) {
                this.didInitHelperCalls = true;
                this.initHelperCalls();
            }
        },
        '$route' () {
            this.didInitialiseRoute = true;

            if (!this.didInitHelperCalls && this.didLoadUser) {
                this.didInitHelperCalls = true;
                this.initHelperCalls();
            }
        }
    },

    computed: {
        loggedInUser() {
            return this.$store.state.loggedInUser;
        },
        organisation() {
            return TrainingContext.getOrganisation();
        },
        helprTrainingLabel() {
            return TrainingContext.getHelprTrainingLabel();
        },
        canChangePassword() {
            return this.$store.state.loggedInUser && this.$store.state.loggedInUser.email.includes('@');
        },
        test() {
            return this.$store.state.test;
        },
    },

    methods: {
        onTrainingFinished() {
            this.$store.dispatch('setTrainingIsFinished', true);
        },
        setPreventRedirect(preventRedirect) {
            this.preventRedirect = preventRedirect;
        },
        logout() {
            AuthenticationManager.invalidateSession();
            this.$router.push({ name: 'login' });
        },
        onChangeClick() {
            if (this.isSubmitting) {
                return;
            }

            this.isSubmitting = true;

            if (this.isChangingName) {
                // Save name
                AxiosApi.request({
                    url: '/api/update-name',
                    method: 'post',
                    data: {
                        name: this.newName,
                    }
                }).then(() => {
                    // ok
                    this.loggedInUser.name = this.newName;
                    this.newName = '';
                    this.isChangingName = false;
                }).finally(() => {
                    this.isSubmitting = false;
                });
            } else if (this.isChangingPassword) {
                // Save password
                AxiosApi.request({
                    url: '/api/update-password',
                    method: 'post',
                    data: {
                        password: this.newPassword,
                    }
                }).then(() => {
                    // ok
                    this.newPassword = '';
                    this.isChangingPassword = false;
                }).finally(() => {
                    this.isSubmitting = false;
                });
            }
        },
        onChangeNameClick() {
            this.newName = this.loggedInUser.name;
            this.isChangingName = true;
        },
        onChangePasswordClick() {
            this.newPassword = '';
            this.isChangingPassword = true;
        },
        onCancelChangeAccount() {
            this.isChangingName = false;
            this.isChangingPassword = false;
            this.newName = '';
            this.newPassword = '';
        },
        async initHelprMethods() {
            if (!('helprAPI' in window)) {
                return;
            }

            const bot = TrainingContext.getHelprTrainingBot();

            await window.helprAPI.start(bot);

            window.helprAPI.startVideo = () => {
                this.$root.$emit('playVideo');
            };

            window.helprAPI.gotoPage = (page) => {
                if (this.preventRedirect) {
                    return;
                }

                let index = parseInt(page);

                if (!isNaN(index)) {
                    let slide = this.$store.getters.getSlideByIndex(index);

                    this.$router.push({
                        name: 'slide',
                        params: {
                            chapterId: slide.chapter.id,
                            slideId: slide.id,
                        }
                    });
                } else {
                    if (1 === this.$router.options.routes.filter((route) => { return route.name === page; }).length) {
                        this.$router.push({
                            name: page,
                        });
                    }
                }
            };

            window.helprAPI.trainingFinished = () => {
                this.onTrainingFinished();
            };
        },

        onSessionResetConfirm() {
            this.confirmResetTestDialog = false;

            TestService.resetTest(this.test).then(() => {
                this.isShowingMyAccountModal = false;
            });
        },

        async initHelperCalls () {
            try {
                if (!('helprAPI' in window)) {
                    return;
                }

                await window.helprAPI.connectMessage({
                    "training": this.helprTrainingLabel,
                });

                switch (this.$route.name) {
                    case 'slide':
                        window.helprAPI.pageMessage({
                            'index': this.$store.getters.getSlideIndexById(this.$route.params.slideId),
                            'training': this.helprTrainingLabel,
                        });
                        break;
                    default:
                        window.helprAPI.pageMessage({
                            'index': this.$route.name,
                            'training': this.helprTrainingLabel,
                        });
                }
            } catch (e) {
                window.console.log('issuesss');
                IssueService.log(e);
            }
        },
    },
};
</script>

<style lang="scss">
    @import 'styles/app';

    html, body {
        height: 100%;
        overflow: hidden !important;
    }
    @media screen and (min-width: 960px) {
        main.v-main {
            padding-top: 0 !important;
        }
    }
    .container.fill-height > .row {
        max-width: 100% !important;
        margin-left: 0;
        margin-right: 0;
    }
    body > .v-application {
        overflow-y: scroll;
        height: 100%;
        background: url('/images/background2.jpg') no-repeat center center !important;
        background-size: cover !important;
    }

    .hidden {
        display: none;
    }

    .v-application.rdw .rdw {
        display: inline-block;
    }

    .v-list-item .v-list-item__subtitle, .v-list-item .v-list-item__title {
        line-height: 3 !important;
    }
    .well {
        padding: 12px;
        border: 1px solid #e4e4e4;
        border-radius: 3px;
        background: #fbfbfb;
        margin: 12px 0 10px;
        font-size: 15px;
        animation: fadein .5s;
        -moz-animation: fadein .5s; /* Firefox */
        -webkit-animation: fadein .5s; /* Safari and Chrome */
        -o-animation: fadein .5s; /* Opera */
    }
    .well-info {
        background: #e3e8ee;
    }
    .well i.v-icon {
        margin-top: -1px;
    }
    p.icon-left {
        position: relative;
        padding-left: 72px;
    }
    p.icon-left i.v-icon {
        font-size: 36px;
        position: absolute;
        left: 16px;
        top: 50%;
        margin-top: -18px;
    }

    .vp-outro-wrapper {
        display: none !important;
    }

    .underline {
        text-decoration: underline;
    }

    .pr-20 {
        padding-right: 20px;
    }
    .mt--20 {
        margin-top: -20px;
    }
    .fs-18 {
        font-size: 18px;
    }

    .powered-by {
        font-size: 12px;
        opacity: 0.9;
    }
    .powered-by span {
        display: inline-block;
        padding-bottom: 2px;
    }

    .v-input--selection-controls .v-input__control {
        width: 100%;
    }

    @keyframes fadein {
        from {
            opacity:0;
        }
        to {
            opacity:1;
        }
    }
    @-moz-keyframes fadein { /* Firefox */
        from {
            opacity:0;
        }
        to {
            opacity:1;
        }
    }
    @-webkit-keyframes fadein { /* Safari and Chrome */
        from {
            opacity:0;
        }
        to {
            opacity:1;
        }
    }
    @-o-keyframes fadein { /* Opera */
        from {
            opacity:0;
        }
        to {
            opacity: 1;
        }
    }
</style>
