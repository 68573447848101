import axios from 'axios'

const AxiosApi = axios.create({
    // baseURL: 'http://api.prodrive-elearning.test',
    baseURL: 'https://api.prodrive-elearning.defineweb.nl',
    headers: {
        'Content-Type': 'application/vnd.api+json',
    },
});

export default AxiosApi
