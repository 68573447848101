<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="8" xl="6">
          <v-card class="elevation-12">
            <HeaderImage/>
            <v-card-text style="max-height: 100%" scrollable class="px-6 overflow-y-auto">
              <h1 class="mb-4">{{ $t('privacy-policy.title') }}</h1>
              <p>
                  {{ $t('privacy-policy.lead') }}
              </p>
              <br>

              <h2 class="mb-2">{{ $t('privacy-policy.intro-title') }}</h2>
              <ul class="mb-3">
                  <li>{{ $t('privacy-policy.intro-item-1') }}</li>
                  <li>{{ $t('privacy-policy.intro-item-2') }}</li>
              </ul>
              <br>

              <h2 class="mb-2">{{ $t('privacy-policy.data-safe-title') }}</h2>
              <p>
                  {{ $t('privacy-policy.data-safe-content') }}
              </p>
              <br>

              <h2 class="mb-2">{{ $t('privacy-policy.storage-time-title') }}</h2>
              <p>
                  {{ $t('privacy-policy.storage-time-content') }}
              </p>
              <br>

              <h2 class="mb-2">{{ $t('privacy-policy.cookies-title') }}</h2>
              <p>
                  {{ $t('privacy-policy.cookies-content') }}
              </p>
              <br>

              <h2 class="mb-2">{{ $t('privacy-policy.analytics-title') }}</h2>
              <p>
                  {{ $t('privacy-policy.analytics-content') }}
                  <br>
              </p>

              <h2 class="mb-2">{{ $t('privacy-policy.contact-title') }}</h2>
              <p v-html="$t('privacy-policy.contact-content')"></p>
              <br>
              <p>
                  <router-link :to="{ name: 'chapters' }">{{ $t('privacy-policy.return-action-label') }}</router-link>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import HeaderImage from './components/HeaderImage';

export default {
    components: {
        HeaderImage,
    },
};
</script>

<style scoped>
    h1 {
        font-size: 26px;
    }
    h2 {
        font-size: 18px;
    }
    img {
        padding: 24px 0 30px;
    }
</style>
