import AxiosApi from '@/services/AxiosApi';
import UserService from '@/services/UserService';
import TrainingContext from '@/services/TrainingContext';
import store from '../store';

const AuthenticationManager = {
    localStorageKey: 'oauth-session',

    _getToken(token, grantType) {
        let data = {
            grant_type: grantType,
            client_id: TrainingContext.getClientId(),
            client_secret: TrainingContext.getClientSecret(),
        };

        if ('refresh_token' === grantType) {
            data['refresh_token'] = token;
        } else if ('access_token' === grantType) {
            data['access_token'] = token;
        }

        return AxiosApi.request({
            url: '/oauth/v2/token',
            method: 'post',
            data: data,
        }).then((result) => {
            this._setOAuth(result.data);

            return new Promise((resolve) => {
                resolve(result.data.access_token);
            });
        }).catch(() => {
            this._setOAuth(null);

            return new Promise((resolve) => {
                resolve(false);
            });
        });
    },

    async getToken () {
        // Check if OAuth object exists in store
        let oauth = store.getters.oauth;

        if (!oauth) {
            // OAuth was not found in the store, check in localStorage
            oauth = this._getOAuthFromLocalStore();

            if (oauth) {
                // OAuth was found in local storage
                this._setOAuth(oauth);
            }
        }

        if (oauth) {
            // Check if token is expired
            if (Math.round(Date.now() / 1000) > oauth.expires_at) {
                // Is expired, refresh token
                this._setOAuth(null);

                return this._getToken(oauth.refresh_token, 'refresh_token');
            } else {
                // Token is still valid
                return new Promise((resolve) => {
                    resolve(oauth.access_token);
                });
            }
        }

        // Not authenticated
        return new Promise((resolve) => {
            resolve(false);
        });
    },

    async isAuthenticated () {
        return this.getToken().then((result) => {
            let isAuthenticated = false;

            if (result) {
                isAuthenticated = true;
            }

            return new Promise((resolve) => {
                resolve(isAuthenticated);
            });
        });
    },

    async authenticateByToken () {
        // First check if token is set in url
        let token = this._getUrlParameter('token');

        if (token) {
            return this._getToken(token, 'access_token').then((result) => {
                let isAuthenticated = false;

                if (result) {
                    isAuthenticated = true;
                }

                return new Promise((resolve) => {
                    resolve(isAuthenticated);
                });
            });
        }

        return new Promise((resolve) => {
            resolve(false);
        });
    },

    invalidateSession () {
        this._setOAuth(null);
        UserService.unloadUser();
    },

    async authenticate (email, pass) {
        return AxiosApi.request({
            url: '/oauth/v2/token',
            method: 'post',
            data: {
                grant_type: 'password',
                username: email,
                password: pass,
                client_id: TrainingContext.getClientId(),
                client_secret: TrainingContext.getClientSecret(),
            }
        });
    },

    _refreshToken () {
        return true;
    },

    _getOAuth() {

    },

    _setOAuth(oauth) {
        if (oauth && oauth.hasOwnProperty('expires_in') && oauth.hasOwnProperty('access_token')) {
            // Token is valid
            if (!oauth.hasOwnProperty('expires_at')) {
                // Token is new, so set expires_at
                oauth.expires_at = Math.round(Date.now() / 1000) + oauth.expires_in;
            }

            AxiosApi.defaults.headers.common['Authorization'] = `Bearer ${oauth.access_token}`;
            localStorage.setItem('oauth-session', JSON.stringify(oauth));
        } else {
            AxiosApi.defaults.headers.common['Authorization'] = '';
            localStorage.setItem('oauth-session', null);
        }

        store.dispatch('setOAuth', oauth);
    },

    _getOAuthFromLocalStore() {
        let hash = localStorage.getItem(this.localStorageKey);

        // Check if localStorage contains oauth
        if (hash && 'null' !== hash && undefined !== hash && '' !== hash)
        {
            return JSON.parse(hash);
        }

        return false;
    },

    _getUrlParameter(param) {
        let sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === param) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    },

    register(data) {
        data.client = TrainingContext.getClientId();

        return AxiosApi.request({
            url: '/api/users',
            method: 'post',
            data: data,
        });
    },
};

export default AuthenticationManager
