import Vue from 'vue';
import App from './App.vue';
import VueI18n from 'vue-i18n';
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import store from './store';
import AuthenticationManager from './services/AuthenticationManager';
import TrainingContext from './services/TrainingContext';
import TestService from './services/TestService';
import UserService from './services/UserService';
import vueVimeoPlayer from 'vue-vimeo-player';
import VueGtag from 'vue-gtag';
// import Hotjar from 'vue-hotjar';

// Routes
import Chapters from './Chapters'
import Chapter from './Chapter'
import Slide from './Slide'
import Question from './Question'
import Registration from './Registration'
import Login from './Login'
import Explanation from './Explanation'
import PrivacyPolicy from './PrivacyPolicy'
import NotFound404 from './NotFound404'
import IssueService from "@/services/IssueService";

Vue.config.productionTip = false;

Vue.config.errorHandler = function(error) {
    IssueService.log(error);
}

Vue.use(VueRouter);

const routes = [
    { name: 'chapters', path: '/', component: Chapters },
    { name: 'slide', path: '/:chapterId/slides/:slideId', component: Slide },
    { name: 'question', path: '/:chapterId/slides/:slideId/questions/:questionId', component: Question },
    { name: 'registration', path: '/registreren', component: Registration, meta: { unauthenticated: true, authenticated: false } },
    { name: 'login', path: '/login', component: Login, meta: { unauthenticated: true, authenticated: false } },
    { name: 'privacyPolicy', path: '/privacy-policy', component: PrivacyPolicy, meta: { authenticated: false } },
    { name: 'explanation', path: '/uitleg', component: Explanation },
    { name: 'chapter', path: '/:chapterId(\\d+)', component: Chapter },
    { name: 'notFound404', path: '*', component: NotFound404 },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    const isAuthenticatedRoute = !to.meta.hasOwnProperty('authenticated') ? true : to.meta.authenticated;
    const isUnauthenticatedRoute = !to.meta.hasOwnProperty('unauthenticated') ? false : to.meta.unauthenticated;

    if (window.location.search.includes('token')) {
        AuthenticationManager.authenticateByToken().then((isAuthenticated) => {
            window.history.replaceState({}, document.title, window.location.origin + window.location.pathname);

            if (isAuthenticated) {
                return next({ name: 'explanation' });
            } else {
                return next({ name: 'login' });
            }
        });
    } else {
        AuthenticationManager.isAuthenticated().then((isAuthenticated) => {
            if (isAuthenticated) {
                if (isUnauthenticatedRoute) {
                    // Go to authentication route index
                    return next({ name: 'chapters' });
                }

                if (!UserService.userIsLoaded()) {
                    // Load user
                    UserService.fetchLoggedInUser();
                }

                if (!TestService.isTestLoaded()) {
                    TestService.loadTest().then(() => {
                        next();
                    });
                } else {
                    next();
                }
            } else {
                // Is not authenticated
                if (isAuthenticatedRoute) {
                    // Go to training portal
                    return next({ name: 'registration' });
                } else {
                    next();
                }
            }
        });
    }
});

router.afterEach((to) => {
    try {
        if ('helprAPI' in window && 'initialized' in window.helprAPI && window.helprAPI.initialized) {
            switch (to.name) {
                case 'slide':
                    window.helprAPI.pageMessage({
                        'index': store.getters.getSlideIndexById(to.params.slideId),
                        'training': TrainingContext.getHelprTrainingLabel(),
                    });
                    break;
                default:
                    window.helprAPI.pageMessage({
                        'index': to.name,
                        'training': TrainingContext.getHelprTrainingLabel(),
                    });
            }
        }
    } catch (e) {
        IssueService.log(e);
    }

    // if (window.location.search.includes('token')) {
    //     window.history.replaceState({}, document.title, window.location.origin + window.location.pathname);
    // }
});

// Localisation
Vue.use(VueI18n);

function loadLocaleMessages () {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}

Vue.use(VueGtag, {
    config: {
        id: "G-PYFZ1ZELWH",
        params: {
            anonymize_ip: true,
            anonymizeIp: true,
        },
    }
}, router);

// Vue.use (Hotjar, {
//     id: '2130260',
//     isProduction: true,
// });

(async () => {
    await TrainingContext.load();

    const i18n = new VueI18n({
        locale: TrainingContext.getLanguage(),
        fallbackLocale: 'nl',
        messages: loadLocaleMessages()
    });

    Vue.use(vueVimeoPlayer);

    new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: h => h(App)
    }).$mount('#app');
})();
