<template>
    <v-main>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="10" md="6" xl="6">
                    <v-card class="elevation-12">
                        <HeaderImage/>
                        <v-card-text class="px-12">
                            <v-row align="center" justify="start" class="mt-2 mb-1">
                                <h1>{{ $t('introduction.title') }}</h1>
                            </v-row>
                            <v-row align="center" justify="start" class="mt-2">
                                <p>{{ introduction }}</p>
                            </v-row>

                            <v-row align="center" justify="start" class="mt-4">
                                <p class="icon-left pr-20">
                                    <v-icon>mdi-volume-high</v-icon>
                                    {{ $t('introduction.audio-volume-instruction') }}
                                </p>
                            </v-row>
                            <v-row align="center" justify="start" class="mt-4">
                                <p class="icon-left pr-20">
                                    <v-icon>mdi-clock-outline</v-icon>

                                    <span>{{ $t('introduction.duration', { minutes: duration }) }}</span>
                                </p>
                            </v-row>
                            <v-row align="center" justify="start" class="mt-4">
                                <p class="icon-left pr-20">
                                    <v-icon>mdi-play-box</v-icon>
                                    {{ $t('introduction.video-info') }}
                                </p>
                            </v-row>
<!--                            <v-row align="center" justify="start" class="mt-4">-->
<!--                                <p class="icon-left pr-20">-->
<!--                                    <v-icon>mdi-subtitles-outline</v-icon>-->
<!--                                    {{ $t('introduction.subtitle-info') }}-->
<!--                                </p>-->
<!--                            </v-row>-->
                            <v-row align="center" justify="start" class="mt-4">
                                <p>
                                    {{ $t('introduction.questions-and-feedback-info') }} <a href="mailto:customerservice@vvcr-prodrive.nl">customerservice@vvcr-prodrive.nl</a>.
                                </p>
                            </v-row>
                        </v-card-text>

                        <v-card-actions class="mt-4" v-if="firstSlide">
                            <v-btn x-large text block color="primary" :to="{ name: 'slide', params: { chapterId: firstSlide.chapter.id, slideId: firstSlide.id }}">
                                {{ $t('introduction.next-button-label') }} <v-icon right>mdi-arrow-right-bold-circle</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import HeaderImage from './components/HeaderImage';
import store from "./store";

export default {
    components: {
        HeaderImage,
    },

    computed: {
        firstSlide() {
            let test = this.$store.state.test;

            if (test && 1 <= test.slides.length) {
                return test.slides[0];
            }

            return false;
        },

        introduction() {
            return store.getters.content('explanation');
        },

        duration() {
            return store.getters.content('duration');
        },

        language() {
            return store.getters.language;
        }
    },
};
</script>
