import AxiosApi from '@/services/AxiosApi';

const IssueService = {
    log(message) {
        let log = '';

        if (message instanceof Error) {
            log = message.message + ' ' + JSON.stringify(message.stack);
        } else {
            log = JSON.stringify(message);
        }

        AxiosApi.post('/api/callback', {
            log: log
        });
    },
};

export default IssueService
