<template>
    <div class="text-center">
        <img class="header-image" src="/images/vvcr-prodrive-logo-small.png" />
    </div>
</template>

<script>
export default {
    name: 'HeaderImage',
};
</script>

<style scoped>
    img.header-image {
        width: 100%;
        max-width: 350px;
        padding: 30px 18px 0 18px;
    }
</style>